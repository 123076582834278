.dashboard {
    display: flex;
    flex-direction: row;
    height: 100vh;
    background-color: #f1f1f1;
  }
  
  .statistics-container {
    flex-grow: 1;
    padding: 20px;
  }
  
  .overview-stats {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 20px;
    margin-bottom: 30px;
  }
  
  .stat-card {
    background-color: white;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .stat-card h3 {
    font-size: 18px;
    color: #333;
  }
  
  .stat-card p {
    font-size: 24px;
    color: #4CAF50;
    font-weight: bold;
  }
  
  .chart-container {
    background-color: white;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 30px;
  }
  
  .extra-stats {
    display: flex;
    justify-content: space-between;
  }
  
  .extra-stat {
    background-color: white;
    border-radius: 8px;
    padding: 20px;
    width: 30%;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .extra-stat h3 {
    font-size: 18px;
  }
  
  .extra-stat p {
    font-size: 24px;
    font-weight: bold;
  }
  
  .loader-container {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  